import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { MetaData } from '../components/common/meta';
import  SinglePost from '../components/CMS/singlePost';
import PageWrapper from '../components/PageWrapper';

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const PostAsPage = ({ data, location }) => {
    const post = data.ghostPost
    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
            />
            <PageWrapper>

                <SinglePost post={post} displayAsPage={true}/>

            </PageWrapper>
        </>
    );
}

PostAsPage.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default PostAsPage

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            plaintext
            html            
            ...GhostPostFields
        }
    }
`
